import axios from 'axios';
import React, { Component } from 'react';
import * as Banner from './Helpers/bannerHelpers';
import * as Stats from './Helpers/statHelpers';
import * as Common from './Helpers/commonHelpers';
import $ from 'jquery';

class Widget extends Component {

    constructor(props) {
        super(props)
        this.state = {
            content: '',
            stylePath: '',
            themeInfo: [],
            widgetInfo: [],
            message: ''
        }
    }

    componentDidMount() {
        if (Stats.getParameterByName("p") && Stats.getParameterByName("clickitsid")) {
            this.getAllTheData();
            this.interval = setInterval(() => {
                this.getAllTheData();
            }, 60000);
        }
        else if (Stats.getParameterByName("mode") && Stats.getParameterByName("template")) {
           
            this.getAllTheData();
            this.interval = setInterval(() => {
                this.getAllTheData();
            }, 60000);
        }
        else {
            this.setState({
                message: 'URL parameters are missing or Wrong parameters...'
            })
        }
    }

    getAllTheData = async () => {
        //let themeResponse =  await this.getThemeJSON();
        await this.getThemeJSON();
        await this.getWidgetInfo(this.state.themeInfo);
        await this.getHTMLThemeInfo(this.state.themeInfo);
    }

    getThemeJSON = () => {
        let url;
        if (Stats.getParameterByName("mode")) {
            if (Stats.getParameterByName("mode") === "draft") {

                /*Local Server URL*/
                url = "../data/draft1.json";

                /* Test Server URL */
                //url = "https://s3.us-east-1.amazonaws.com/aws.cause.clickandpledge.dev/salesforce/"+Common.getOrgId()+"/assets/clickits/"+Common.getClickitsId()+"/draft.json";
                //url = "https://aws.cause.clickandpledge.dev/salesforce/"+Common.getOrgId()+"/assets/clickits/"+Common.getClickitsId()+"/draft.json";

                /* Live Server URL */
                //url = "https://aws.cause.clickandpledge.com/salesforce/"+Common.getOrgId()+"/assets/clickits/"+Common.getClickitsId()+"/draft.json";
                
            }
            else if (Stats.getParameterByName("mode") === "publish") {

                /* Local Server URL */
                url = "../data/publish.json";

                /* Test Server URL */
                //url = "https://s3.us-east-1.amazonaws.com/aws.cause.clickandpledge.dev/salesforce/"+Common.getOrgId()+"/assets/clickits/"+Common.getClickitsId()+"/publish.json";
                //url = "https://aws.cause.clickandpledge.dev/salesforce/"+Common.getOrgId()+"/assets/clickits/"+Common.getClickitsId()+"/publish.json";

                /* Live Server URL */
                //url = "https://aws.cause.clickandpledge.com/salesforce/"+Common.getOrgId()+"/assets/clickits/"+Common.getClickitsId()+"/publish.json";

            } else if (Stats.getParameterByName("mode") === "new") {

                /* Local Server URL */
                url = "../data/json/sample_"+Stats.getParameterByName("template")+".json";

                /* Test Server URL */
                //url = "https://aws.assets.clickandpledge.dev/salesforce/clickits/templates/sample_"+Stats.getParameterByName("template")+".json";
                
                /* Live Server URL */
                //url = "https://aws.assets.clickandpledge.com/salesforce/clickits/templates/sample_"+Stats.getParameterByName("template")+".json";
            }
            else {
                url = '';
            }
        }
        return new Promise((resolve, reject) => {
            const response = axios.get(url);
            response.then((res) => {

                let dataSlice;
                let themeData = res.data
                if (Stats.getParameterByName("template") && Stats.getParameterByName("type")) {
                    let tType = Stats.getParameterByName("type");
                    dataSlice = { ...themeData.GlobalSettings, ThemeType: tType };
                    themeData = { ...themeData, GlobalSettings: dataSlice };
                }

                this.setState({
                    themeInfo: themeData,
                    message: ''
                });
                resolve(res.data);
            }).catch((err) => {
                this.setState({
                    message: "Sorry! something went wrong."
                });
                reject(err.message)
            })

        })
    }

    getHTMLThemeInfo = (response) => {
        //let styleURL = "https://aws.assets.clickandpledge.dev/salesforce/clickits/templates/"+response.ClickItsColorImage.ThemeType+"/css/"+response.ClickItsColorImage.SelectedThemeId+".css";
        //let themeURL = "https://aws.assets.clickandpledge.dev/salesforce/clickits/templates/"+response.ClickItsColorImage.ThemeType+"/"+response.ClickItsColorImage.SelectedThemeId+".html";

        let styleURL, themeURL;

        if (Stats.getParameterByName("template")) {
            // For Local
            let widget = Stats.getParameterByName("template");

            /* Local Assets URLs */
            styleURL = "../data/css/" + widget + ".css";
            themeURL = "../data/" + widget + ".html";
            /*Test Assets URL*/
            //styleURL = "https://aws.assets.clickandpledge.dev/salesforce/clickits/templates/"+response.GlobalSettings.ThemeType+"/css/"+widget+".css";
            //themeURL = "https://aws.assets.clickandpledge.dev/salesforce/clickits/templates/"+response.GlobalSettings.ThemeType+"/"+widget+".html";

            /*Live Assets URL*/
            //styleURL = "https://aws.assets.clickandpledge.com/salesforce/clickits/templates/"+response.GlobalSettings.ThemeType+"/css/"+widget+".css";
            //themeURL = "https://aws.assets.clickandpledge.com/salesforce/clickits/templates/"+response.GlobalSettings.ThemeType+"/"+widget+".html";


            if(document.getElementById("styleid") === null){
                let link = document.createElement("link");
                link.setAttribute("rel", "stylesheet");
                link.setAttribute("id", "styleid");
                link.setAttribute("href", styleURL);
                document.head.appendChild(link);
            }
        }
        else {
            
            /* Local Assets URL */
            styleURL = "../data/css/" + response.GlobalSettings.ThemeId + ".css";
            themeURL = "../data/" + response.GlobalSettings.ThemeId + ".html";
            
            /* Test Assets URL */
            //styleURL = "https://aws.assets.clickandpledge.dev/salesforce/clickits/templates/"+response.GlobalSettings.ThemeType+"/css/"+response.GlobalSettings.ThemeId+".css";
            //themeURL = "https://aws.assets.clickandpledge.dev/salesforce/clickits/templates/"+response.GlobalSettings.ThemeType+"/"+response.GlobalSettings.ThemeId+".html";

            /* Live Assets URL */
            //styleURL = "https://aws.assets.clickandpledge.com/salesforce/clickits/templates/"+response.GlobalSettings.ThemeType+"/css/"+response.GlobalSettings.ThemeId+".css";
            //themeURL = "https://aws.assets.clickandpledge.com/salesforce/clickits/templates/"+response.GlobalSettings.ThemeType+"/"+response.GlobalSettings.ThemeId+".html";


            if(document.getElementById("styleid") === null){
                let link = document.createElement("link");
                link.setAttribute("rel", "stylesheet");
                link.setAttribute("id", "styleid");
                link.setAttribute("href", styleURL);
                document.head.appendChild(link);
            }
        }


        return new Promise((resolve, reject) => {
            const response = axios.get(themeURL);
            response.then((res) => {
                this.setState({
                    //stylePath: styleURL,
                    content: res.data
                });
                resolve(res.data);
            }).catch((err) => {
                this.setState({
                    message: "Sorry! something went wrong."
                });
                reject(err.message)
            })
        })
    }

    getWidgetInfo = (response) => {
        let WidgetURL = response.GlobalSettings.SalesforceSiteURL
        return new Promise((resolve, reject) => {
            const response = axios.get(WidgetURL);
            response.then((res) => {
                this.setState({
                    widgetInfo: res.data
                })
                resolve(res.data);
            }).catch((err) => {
                this.setState({
                    message: "Sorry! something went wrong."
                });
                reject(err.message)
            });
        });
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }
    componentDidUpdate() {
        
        if ((Stats.getParameterByName("p") && Stats.getParameterByName("clickitsid")) || Stats.getParameterByName("template")) {
            let { themeInfo, widgetInfo, message } = this.state;
            if (message === "") {
                let themeBGColor = themeInfo.GlobalSettings && themeInfo.GlobalSettings.BackgroundColor;
                //Banner Image or Back Ground Image and BackGround Color
                
                if(document.querySelector(".cpwidg-container")){
                    let mainBgColor = themeInfo.GlobalSettings && themeInfo.GlobalSettings.BackgroundColor;
                    if(mainBgColor){
                        document.querySelector(".cpwidg-container").style.backgroundColor = mainBgColor;
                    }
                }
                if (themeInfo.GlobalSettings.ThemeType === "horizontal") {
                    if (document.querySelector(".cpwidg-banner-imgs")) {
                        const div = document.querySelector('.cpwidg-banner-imgs')
                        const style = window.getComputedStyle(div, false)
                        const bi = style.backgroundImage.slice(4, -1).replace(/"/g, "");
                        //console.log(bi)
                        if (bi === "") {
                            document.querySelector(".cpwidg-banner-imgs").style.cssText = "background-image: url('" + Banner.getBackgroundImage(themeInfo, widgetInfo) + "') !important; background-size:cover;background-repeat:no-repeat; background-position:center;background-color:"+themeBGColor+" !important;";
                        } else {
                            let imgpath = Banner.getBackgroundImage(themeInfo, widgetInfo);
                            if (imgpath !== bi) {
                                document.querySelector(".cpwidg-banner-imgs").style.cssText = "background-image: url('" + imgpath + "') !important; background-size:cover; background-repeat:no-repeat; background-position:center; background-color:"+themeBGColor+" !important;";
                            }
                        }
                    }
                }
                else {
                    
                    if (document.querySelector(".cpwidg-banner-imgs")) {
                        if (document.querySelector(".cpwidg-banner-imgs")) {

                            const div = document.querySelector('.cpwidg-banner-imgs')
                            const style = window.getComputedStyle(div, false)
                            const bi = style.backgroundImage.slice(4, -1).replace(/"/g, "");
                            if (bi === "") {
                                document.querySelector(".cpwidg-banner-imgs").style.cssText = "background-image: url('" + Banner.getBackgroundImage(themeInfo, widgetInfo) + "') !important; background-size:cover;background-repeat:no-repeat; background-position:center;background-color:"+themeBGColor+" !important;";
                            } else {
                                let imgpath = Banner.getBackgroundImage(themeInfo, widgetInfo);
                                if(imgpath !== false)
                                {
                                    if (imgpath !== bi) {
                                        document.querySelector(".cpwidg-banner-imgs").style.cssText = "background-image: url('" + imgpath + "') !important; background-size:cover;background-repeat:no-repeat; background-position:center;background-color:"+themeBGColor+" !important;";
                                    }
                                }
                                else{
                                    if(document.querySelector(".cpwidg-banner-imgs")){
                                        document.querySelector(".cpwidg-banner-imgs").remove();
                                    }
                                }
                            }
                        }
                    }
                    //short widget
                    if (document.querySelector(".cpwidg-container-bg")) {
                        if (document.querySelector(".cpwidg-container-bg")) {

                            const div = document.querySelector('.cpwidg-container-bg')
                            const style = window.getComputedStyle(div, false)
                            const bi = style.backgroundImage.slice(4, -1).replace(/"/g, "");
                            if (bi === "") {
                                document.querySelector(".cpwidg-container-bg").style.cssText = "background-image: url('" + Banner.getBackgroundImage(themeInfo, widgetInfo) + "') !important; background-size:cover;background-repeat:no-repeat; background-position:center;background-color:"+themeBGColor+" !important;";
                            } else {
                                let imgpath = Banner.getBackgroundImage(themeInfo, widgetInfo);
                                if(imgpath !== false)
                                {
                                    if (imgpath !== bi) {
                                        document.querySelector(".cpwidg-container-bg").style.cssText = "background-image: url('" + imgpath + "') !important; background-size:cover;background-repeat:no-repeat; background-position:center;background-color:"+themeBGColor+" !important;";
                                    }
                                }
                                else{
                                    if(document.querySelector(".cpwidg-container-bg")){
                                        document.querySelector(".cpwidg-container-bg").remove();
                                    }
                                }
                            }
                        }
                    }
                }
                // Title 1 Section
                if (document.querySelector("#cpWidgCampaignTitle")) {
                    if (themeInfo && themeInfo.TextSections) {
                        let CampaignDescVisibility = themeInfo.TextSections && themeInfo.TextSections.FirstTextSection && themeInfo.TextSections.FirstTextSection.Visibility;
                        if (CampaignDescVisibility) {

                            if (themeInfo.TextSections.FirstTextSection && themeInfo.TextSections.FirstTextSection.Description) {
                                document.querySelector("#cpWidgCampaignTitle").innerHTML = themeInfo.TextSections.FirstTextSection.Description;
                            }
                            else {
                                document.querySelector("#cpWidgCampaignTitle").innerHTML = ""
                            }
                        } else {
                            if (document.querySelector("#cpWidgCampaignTitle")) {
                                document.querySelector("#cpWidgCampaignTitle").remove();
                            }
                        }
                    }
                }
                //Donate Button
                if (document.querySelector("#cpWidgSupport")) {
                    let donateButtonInfo = themeInfo.Buttons ? themeInfo.Buttons.DonateButton : null;
                    if (donateButtonInfo) {
                        
                        if (document.querySelector("#cpWidgSupportBtn")) {
                            document.querySelector("#cpWidgSupportBtn").innerHTML = donateButtonInfo.ButtonLabel;
                            document.querySelector("#cpWidgSupportBtn").style.cssText = Stats.getButtonStyles(themeInfo, "Buttons", "DonateButton");
                            document.querySelector("#cpWidgSupportBtn").onmouseover = function () {
                                document.querySelector("#cpWidgSupportBtn").style.cssText = Stats.getButtonHoverStyles(themeInfo, "Buttons", "DonateButton");
                            }
                            document.querySelector("#cpWidgSupportBtn").onmouseout = function () {
                                document.querySelector("#cpWidgSupportBtn").style.cssText = Stats.getButtonStyles(themeInfo, "Buttons", "DonateButton");
                            }
                            let cedv = themeInfo.GlobalSettings && themeInfo.GlobalSettings.CampaignEndDate;
                            if (cedv !== "") {
                                if (Stats.calculateNoofDays(cedv) > -1 || themeInfo.GlobalSettings.ButtonsHideAfterEndDate === false) {
                                        document.querySelector("#cpWidgSupportBtn").onclick = function () {
                                            let cid = themeInfo.Buttons && themeInfo.Buttons.ConnectForm;
                                            if (cid) {
                                                window.open("https://connect.clickandpledge.com/w/Form/" + cid, "_blank");
                                            }
                                        }
                                }else{
                                    
                                    if(document.querySelector("#cpWidgSupportBtn")){
                                        document.querySelector("#cpWidgSupportBtn").remove();
                                    }
                                }
                            }
                            else if (cedv === "" && themeInfo.GlobalSettings.ButtonsHideAfterEndDate === false) {
                                document.querySelector("#cpWidgSupportBtn").onclick = function () {
                                    let cid = themeInfo.Buttons.ConnectForm;
                                    if (cid) {
                                        window.open("https://connect.clickandpledge.com/w/Form/" + cid, "_blank");
                                    }

                                }
                            }
                            else{
                                if(document.querySelector("#cpWidgSupportBtn")){
                                    document.querySelector("#cpWidgSupportBtn").remove();
                                }
                            }
                        }

                    }
                }// Donate button End

                // Stats Section
               
                if (document.querySelector(".cpwidg-container #cpwidgPaymentSection")) {
                    if (document.querySelector("#cpWidgFundProgress")) {
                        // Progress meter
                        let symbol = Stats.getCurrencySymbol(themeInfo);
                        let progressMeterVisibility = themeInfo && themeInfo.ProgressMeter && themeInfo.ProgressMeter.Visibility;
                        let statsVisibility = themeInfo && themeInfo.ProgressMeter && themeInfo.ProgressMeter.CampaignStatsVisibility;
                        let goalAmt = widgetInfo.ExpectedRevenue
                        let expectedRevenue = widgetInfo.AmountWonOpportunities;
                        let campaignInfo = themeInfo.CampaignTextSection;
                        let separator = themeInfo.GlobalSettings && themeInfo.GlobalSettings.NumberFormat && themeInfo.GlobalSettings.NumberFormat.DecimalSeparator;
                        let decimalsToShow = themeInfo.GlobalSettings && themeInfo.GlobalSettings.NumberFormat && themeInfo.GlobalSettings.NumberFormat.NumOfDecimalsToShow;
                        let meterColor = themeInfo.ProgressMeter && themeInfo.ProgressMeter.MeterColor;
                        if (themeInfo.GlobalSettings.ThemeType === "horizontal") {
                            // Theme Info
                            if (campaignInfo) {
                                if (document.querySelector("#cpWidgGoal")) {
                                    document.querySelector("#cpWidgGoal").innerHTML = Common.formatGoalInfo(themeInfo, goalAmt, expectedRevenue);
                                }
                            } else {
                                if (document.querySelector("#cpWidgGoal")) {
                                    document.querySelector("#cpWidgGoal").remove();
                                }
                            }
                            // Flask Meter or Progress meter
                            if (progressMeterVisibility) {
                                if (document.querySelector(".cpwidg-meterbox")) {
                                    if (document.querySelector(".cpwidg-progresstotal")) {
                                        document.querySelector(".cpwidg-progresstotal").innerHTML = goalAmt ? symbol + goalAmt : "";
                                    }
                                    if (document.querySelector(".cpwidg-exptotal")) {
                                        if (document.querySelector(".cpwidg-exptotal")) {
                                            document.querySelector(".cpwidg-exptotal").innerHTML = expectedRevenue ? symbol + expectedRevenue : "";
                                        }
                                        if (document.querySelector(".cpwidg-exptotal")) {
                                            document.querySelector(".cpwidg-exptotal").style.bottom = Stats.calculatePercentage(goalAmt, expectedRevenue) + "%";
                                        }
                                    }
                                    if (document.querySelector(".cpwidg-progressflask")) {
                                        let percentage = 0;
                                        if (Stats.calculatePercentage(goalAmt, expectedRevenue) >= 0) {
                                            percentage = Number(Stats.calculatePercentage(goalAmt, expectedRevenue)).toFixed(0)
                                        }
                                        document.querySelector(".cpwidg-progressflask").innerHTML = percentage + "%";
                                        if(meterColor){
                                            document.querySelector(".cpwidg-progressflask").style.cssText = "background-color: "+meterColor+";";
                                            document.querySelector(".cpwidg-progressbgcolor").style.cssText = "background-color: "+meterColor+";";
                                        }
                                    }
                                    if (document.querySelector(".cpwidg-progressbgcolor")) {
                                        document.querySelector(".cpwidg-progressbgcolor").style.height = Stats.calculatePercentage(goalAmt, expectedRevenue) + "%";
                                    }
                                }
                            } else {
                                if (document.querySelector(".cpwidg-meterbox")) {
                                    document.querySelector(".cpwidg-meterbox").remove();
                                }
                                if (document.querySelector("#cpWidgProgressBar")) {
                                    document.querySelector("#cpWidgProgressBar").remove();
                                }
                            }

                            // Progress meter stats visibility
                            if (statsVisibility && progressMeterVisibility) {
                                if (document.querySelector("#raisedAmt")) {
                                    document.querySelector("#raisedAmt").innerHTML = "Raised: " + symbol + Stats.addDecimalSeparator(separator, expectedRevenue, decimalsToShow);
                                }
                            } else {
                                if (document.querySelector("#raisedAmt")) {
                                    document.querySelector("#raisedAmt").remove();
                                }
                            }

                        }
                        else {
                            // theme info Vertical
                            
                            if (campaignInfo) {
                                if (document.querySelector("#cpWidgGoal")) {
                                    document.querySelector("#cpWidgGoal").innerHTML = Common.formatGoalInfo(themeInfo, goalAmt, expectedRevenue);
                                }
                            } else {
                                if (document.querySelector("#cpWidgFundProgress")) {
                                    document.querySelector("#cpWidgFundProgress").remove();
                                }
                            }
                            // Progress meter
                            if (progressMeterVisibility) {
                                
                                if (document.querySelector("#cpWidgProgressBar")) {
                                    let barInfo = document.querySelector("#cpWidgProgressBar");
                                    if (barInfo) {
                                        barInfo.value = Stats.calculatePercentage(goalAmt, expectedRevenue);
                                        //const cssTemplateString = `progress::-webkit-progress-value{background-color: red !important}`;
                                        //barInfo.style = cssTemplateString;
                                    }
                                    if(meterColor){ 
                                        if(document.querySelector("#internalstyleid")===null){
                                            let head = document.head || document.getElementsByTagName('head')[0];
                                            let style = document.createElement('style');
                                            style.setAttribute("id","internalstyleid");
                                            style.innerHTML = "progress::-webkit-progress-value {background-color: "+meterColor+" !important} progress::-moz-progress-bar {background-color: "+meterColor+" !important}";
                                            head.appendChild(style);
                                        }

                                    }
                                }
                            }
                            else {
                                if (document.querySelector("#cpWidgProgressBar")) {
                                    document.querySelector("#cpWidgProgressBar").remove();
                                }
                                if (document.querySelector(".cpwidg-meterbox")) {
                                    document.querySelector(".cpwidg-meterbox").remove();
                                }
                            }

                            // Progress meter stats visibility
                            if (statsVisibility && progressMeterVisibility) {
                                if (document.querySelector("#raisedAmt")) {
                                    document.querySelector("#raisedAmt").innerHTML = "Raised: " + symbol + Stats.addDecimalSeparator(separator, expectedRevenue, decimalsToShow);
                                }
                            } else {
                                if (document.querySelector("#raisedAmt")) {
                                    document.querySelector("#raisedAmt").remove();
                                }
                            }
                           
                            // All Meters
                            if (document.querySelector(".cpwidg-meterbox")) {
                               
                                if(document.querySelector("#cpwidg-progress-bar"))
                                {
                                    let percentageValue = Stats.calculatePercentage(goalAmt, expectedRevenue);
                                    percentageValue = parseInt(percentageValue);
                                    console.log("Hello")
                                    $('style').append('.progress-bar::before{content: "' + percentageValue + '%";}');
                                    $('style').append('.progress-bar{background:radial-gradient(closest-side, #e3eaf3 79%, transparent 80% 100%), conic-gradient('+meterColor+' ' + percentageValue + '%, #f6cfbd 0);}');
                                }
                                //Thermometer
                                if(document.querySelector("#thermometer")){

                                    var $thermo = $("#thermometer"),
                                    $progress = $(".progress", $thermo),
                                    $goal = $(".goal", $thermo), animate = true;

                                    console.log($thermo)
                                    console.log($progress)
                                    console.log($goal)

                                    let percentageAmount = Math.min(Math.round(expectedRevenue / goalAmt * 1000) / 10, 100); 
                                    $goal.find(".amount").text("$" + Common.formatCurrency(goalAmt));
			                        $progress.find(".amount").text(((expectedRevenue / goalAmt) * 100).toFixed() + "%");
                                    $progress.find(".amount").hide();
                                    $progress.css("background", meterColor);
                                    if (animate !== false) {
                                        $progress.animate({
                                            "height": percentageAmount + "%"
                                        }, 1200, function () {
                                            $(this).find(".amount").fadeIn(500);
                                        });
                                    }
                                    else {
                                        $progress.css({
                                            "height": percentageAmount + "%"
                                        });
                                        $progress.find(".amount").fadeIn(500);
                                    }

                        
                                }
                                
                                if (document.querySelector(".cpwidg-progresstotal")) {
                                    document.querySelector(".cpwidg-progresstotal").innerHTML = goalAmt ? symbol + goalAmt : "";
                                }
                                if (document.querySelector(".cpwidg-exptotal")) {
                                    if (document.querySelector(".cpwidg-exptotal")) {
                                        document.querySelector(".cpwidg-exptotal").innerHTML = expectedRevenue ? symbol + expectedRevenue : "";
                                    }
                                    if (document.querySelector(".cpwidg-exptotal")) {
                                        let positionText = parseInt(Stats.calculatePercentage(goalAmt, expectedRevenue));
                                        if (positionText >= 75 && positionText < 100) {
                                            document.querySelector(".cpwidg-exptotal").style.bottom = "75%";
                                        }
                                        else if(positionText >= 100){
                                            document.querySelector(".cpwidg-exptotal").style.bottom = "100%";
                                        }
                                        else{
                                            document.querySelector(".cpwidg-exptotal").style.bottom = Stats.calculatePercentage(goalAmt, expectedRevenue) + "%";
                                        }
                                        //document.querySelector(".cpwidg-exptotal").style.bottom = Stats.calculatePercentage(goalAmt, expectedRevenue) + "%";
                                    }
                                }
                                if (document.querySelector(".cpwidg-progressflask")) {
                                    let percentage = 0;
                                    if (Stats.calculatePercentage(goalAmt, expectedRevenue) >= 0) {
                                        percentage = Number(Stats.calculatePercentage(goalAmt, expectedRevenue)).toFixed(0)
                                    }
                                    document.querySelector(".cpwidg-progressflask").innerHTML = percentage + "%";
                                    if(meterColor){
                                        document.querySelector(".cpwidg-progressflask").style.cssText = "background-color: "+meterColor+";";
                                        document.querySelector(".cpwidg-progressbgcolor").style.cssText = "background-color: "+meterColor+";";
                                    }

                                }
                                if (document.querySelector(".cpwidg-progressbgcolor")) {
                                    let positionVal = parseInt(Stats.calculatePercentage(goalAmt, expectedRevenue));
                                    if(positionVal >= 98)
                                    {
                                        document.querySelector(".cpwidg-progressbgcolor").style.cssText = "height: "+Stats.calculatePercentage(goalAmt, expectedRevenue)+"%; border-radius: 40px 40px 0px 0px";
                                    }
                                    else{
                                        document.querySelector(".cpwidg-progressbgcolor").style.cssText = "height: "+Stats.calculatePercentage(goalAmt, expectedRevenue)+"%";
                                    }
                                    //document.querySelector(".cpwidg-progressbgcolor").style.height = Stats.calculatePercentage(goalAmt, expectedRevenue) + "%";
                                }
                            }
                            else {
                                if (document.querySelector(".cpwidg-meterbox")) {
                                    document.querySelector(".cpwidg-meterbox").remove();
                                }
                            }

                        }
                    }
                }

                // Fundraising Section
                if (document.querySelector("#cpWidgFundTitle")) {
                    // Fundraiser Description
                    if (themeInfo && themeInfo.TextSections) {
                        let FSVisibility = themeInfo.TextSections.SecondTextSection && themeInfo.TextSections.SecondTextSection.Visibility;
                        if (FSVisibility) {
                            let fundDesc = themeInfo.TextSections.SecondTextSection ? themeInfo.TextSections.SecondTextSection.Description : null;
                            //description
                            if (fundDesc) {
                                if (document.querySelector("#cpWidgFundTitle")) {
                                    document.querySelector("#cpWidgFundTitle").innerHTML = fundDesc;
                                }
                            } else {
                                if (document.querySelector("#cpWidgFundTitle")) {
                                    document.querySelector("#cpWidgFundTitle").remove();
                                }
                            }
                        } else {
                            if (document.querySelector("#cpWidgFundTitle")) {
                                document.querySelector("#cpWidgFundTitle").remove();
                            }
                        }
                    }
                    else {
                        if (document.querySelector("#cpWidgFundTitle")) {
                            document.querySelector("#cpWidgFundTitle").remove();
                        }
                    }

                    // Fundraiser Button
                    let fundBtnVisibility = themeInfo.Buttons && themeInfo.Buttons.FundraiserButton ? themeInfo.Buttons.FundraiserButton.Visibility : null;
                    let buttonInfo = themeInfo.Buttons && themeInfo.Buttons.FundraiserButton ? themeInfo.Buttons.FundraiserButton : null;

                    if (buttonInfo && fundBtnVisibility) {
                        if(document.querySelector("#cpWidgFundraiserBtn"))
                        {
                            document.querySelector("#cpWidgFundraiserBtn").innerHTML = buttonInfo.ButtonLabel;
                            document.querySelector("#cpWidgFundraiserBtn").style.cssText = Stats.getButtonStyles(themeInfo, "Buttons", "FundraiserButton");
                            document.querySelector("#cpWidgFundraiserBtn").onmouseover = function () {
                                document.querySelector("#cpWidgFundraiserBtn").style.cssText = Stats.getButtonHoverStyles(themeInfo, "Buttons", "FundraiserButton");
                            }
                            document.querySelector("#cpWidgFundraiserBtn").onmouseout = function () {
                                document.querySelector("#cpWidgFundraiserBtn").style.cssText = Stats.getButtonStyles(themeInfo, "Buttons", "FundraiserButton");
                            }
                        }
                        

                        let camEndt = themeInfo.GlobalSettings.CampaignEndDate;

                        if (camEndt !== "") {
                            if (Stats.calculateNoofDays(camEndt) > -1 || themeInfo.GlobalSettings.ButtonsHideAfterEndDate === false) {
                                document.querySelector("#cpWidgFundraiserBtn").onclick = function () {
                                    if(buttonInfo.ButtonLink){
                                        window.open(buttonInfo.ButtonLink, "_blank");
                                    }
                                }
                            }
                            else if(!themeInfo.GlobalSettings.ButtonsHideAfterEndDate){
                                document.querySelector("#cpWidgFundraiserBtn").onclick = function () {
                                    if(buttonInfo.ButtonLink){
                                        window.open(buttonInfo.ButtonLink, "_blank");
                                    }
                                }
                            }
                            else{
                                if(document.querySelector("#cpWidgFundraiserBtn")){
                                    document.querySelector("#cpWidgFundraiserBtn").remove();
                                }
                            }
                        }
                        else if (camEndt === "" && themeInfo.GlobalSettings.ButtonsHideAfterEndDate === false) {
                           
                            document.querySelector("#cpWidgFundraiserBtn").onclick = function () {
                                window.open(buttonInfo.ButtonLink, "_blank");
                            }
                        }
                        else
                        {
                            if (document.querySelector("#cpWidgFundraiser")) {
                                document.querySelector("#cpWidgFundraiser").remove();
                            }
                        }
                    } else {
                        if (document.querySelector("#cpWidgFundraiser")) {
                            document.querySelector("#cpWidgFundraiser").remove();
                        }
                    }
                }
                // Payment Live Stream
                if (document.querySelector("#cpWidgRecentDonors")) {
                    if (themeInfo && themeInfo.PaymentStream) {
                        
                        let plsVisible = themeInfo.PaymentStream && themeInfo.PaymentStream.Visibility;
                        let streamText = themeInfo.PaymentStream && themeInfo.PaymentStream.StreamText;
                        let members = widgetInfo && widgetInfo.Members
                        if (members && members.length > 0) {
                            if (plsVisible) {
                                let txt = ''
                                for (let i = 0; i < members.length; i++) {
                                    txt += '<div class="cpwidg-donation-streams" id="cpWidgDonationStreams' + i + '">';
                                    txt += '<div class="cpwidg-list" id="cpWidgList' + i + '">';
                                    txt += Common.FormatStream(streamText, members[i], themeInfo);
                                    txt += '</div>';
                                    txt += '</div>';
                                }
                                if (document.querySelector("#cpWidgMarquee")) {
                                    document.querySelector("#cpWidgMarquee").innerHTML = "";
                                    document.querySelector("#cpWidgMarquee").innerHTML = txt;
                                }
                            } else {
                                if (document.querySelector("#cpWidgMarquee")) {
                                    document.querySelector("#cpWidgMarquee").remove();
                                }
                                if (document.querySelector("#cpWidgRecentDonors")) {
                                    document.querySelector("#cpWidgRecentDonors").remove();
                                }
                            }
                        } else {
                            if (document.querySelector("#cpWidgRecentDonors")) {
                                document.querySelector("#cpWidgRecentDonors").remove();
                            }
                        }

                    }/*else{
                    
                    if(document.querySelector("#cpWidgRecentDonors")){
                        document.querySelector("#cpWidgRecentDonors").remove();
                    }
                }*/
                }

                if (themeInfo && themeInfo.GlobalSettings) {
                    if (themeInfo.GlobalSettings.ThemeType === "horizontal") {

                        let plsV = themeInfo.PaymentStream && themeInfo.PaymentStream.Visibility;
                        let meterV = themeInfo.ProgressMeter && themeInfo.ProgressMeter.Visibility;
                        if (plsV === false && meterV === false) {
                            if (document.querySelector("#cpWidgMainRight")) {
                                document.querySelector("#cpWidgMainRight").remove();
                            }
                        }

                    }
                }
            }
        }//url parameter end
    }// didUpdate end

    render() {
        return (
            <div>
                {this.state.message ? <p>{this.state.message}</p> : null}
                <>
                    <div dangerouslySetInnerHTML={{ __html: this.state.content }}></div>
                </>
            </div>
        )
    }
}

export default Widget